import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { apiService } from "../../../_services/api.service";
import { connect } from "react-redux";
import PaginationComponent from "react-reactstrap-pagination";
import Field from '../../../_common/field';
import pluralize from "pluralize";
import Notification from "../../../_common/notification";
import { ToastContainer, toast } from 'react-toastify';
import validate from 'validate.js';
// import { settingsService } from "../../../_services/settings.service";
import "react-datepicker/dist/react-datepicker.css";
import config from "../../../_config";
// import io from 'socket.io-client';
import RatingSelector from "../../../_ui_components/RatingSelector";
import './style.css'
import FieldsGenerator from "./FieldsGenerator";
import FieldViewer from "./FieldViewer";
import getImageURL from "../../../_helpers/imageHelper";

// import { useLocation } from 'react-router-dom'


// eslint-disable-next-line no-extend-native
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};
class DataTable extends Component {
  state = {
    // activePagination: {
    //   pageLoading: true,
    //   selectedPage: 1,
    //   pageSize: 10,
    //   maxPaginationNumbers: 9
    // },
    total: null,
    per_page: null,
    current_page: null,
    searching: false,
    modelType: null,
    list_all: null,
    list: [],
    listFiltered: [],
    route: "",
    loaded: false,
    searched: false,
    searchText: "",
    filtered: false,
    selectedRows: [],
  };
  componentDidMount() {
    let { location } = this.props;

    let route = pluralize.plural(location.pathname.split("/")[1]);
    this.setState({ route, searchText: "" });

    apiService.type(route).then(m => {
      this.setState({ modelType: m });

      if (m.permission.search) {
        // apiService.getAll(this.state.route).then(list_all => {
        //   this.setState({ list_all });
        // })
      }
    })

    this.fetchData(route);



    // let socket = io(config.baseUrl, config.socketHeader);
    // console.log(pluralize.singular(route));
    // socket.on("new-" + pluralize.singular(route), (m) => {
    //   // this.fetchData(route);

    //   // let list = this.state.list;
    //   // list.insert(0, m);
    //   // this.setState({ list, listFiltered: list })
    // });
    // socket.on("update-" + pluralize.singular(route), (m) => {
    //   // this.fetchData(route);

    //   // let list = this.state.list;
    //   // let foundIndex = list.findIndex(n => n._id === m._id);
    //   // if (foundIndex !== -1)
    //   //   list[foundIndex] = m;
    //   // this.setState({ list, listFiltered: list })
    // });
    // socket.on("delete-" + pluralize.singular(route), (m) => {
    //   // this.fetchData(route);

    //   // let list = this.state.list;
    //   // let foundIndex = list.findIndex(n => n._id === m);
    //   // if (foundIndex !== -1)
    //   //   list.splice(foundIndex, 1);
    //   // this.setState({ list, listFiltered: list })
    // });
  }
  fetchData = async (route, _page = null) => {
    const query = new URLSearchParams(this.props.location.search);

    let page = _page ? _page : JSON.parse(JSON.stringify(query.get("page"))) || 1;
    if (query.get("filter")) {
      this.setState({ filtered: true });
      let filterQuery = JSON.parse(JSON.stringify(query.get("filter")));
      apiService.filter(route, { query: JSON.parse(filterQuery), sortQuery: { dateTime: - 1 } }, { page }).then(this.setCallbackResponse);
    } else {
      this.setState({ filtered: false });
      if (this.state.searchText !== "") {
        apiService.searchWithPagination(route, page, this.state.searchText).then(this.setCallbackResponse);
      } else {
        apiService.getWithPagination(route, page).then(this.setCallbackResponse);
      }

    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        loaded: true, current_page: null
      });
      this.fetchData(this.state.route);
    }
  }
  setCallbackResponse = (response) => {
    this.setState({
      list: response.data, listFiltered: response.data, loaded: true,
      total: response.total,
      per_page: response.per_page,
      current_page: response.current_page,
      searched: false
    })
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.fetchData(this.state.route, 1);
  }


  doSearch = async ({ target }) => {
    if (!this.state.searching) {
      const { value } = target;
      let tempTistFiltered = this.state.list_all;
      if (tempTistFiltered) {
        if (value === "") {
          this.fetchData(this.state.route)
          return;
        }
        this.setState({ searching: true });
        // let listFiltered = JSON.parse(JSON.stringify(this.state.list));
        let searchText = value.toLowerCase();
        let newTempList = [];
        if (tempTistFiltered && tempTistFiltered.length > 0) {
          tempTistFiltered.forEach(item => {
            try {
              Object.keys(this.state.modelType.bluePrint).forEach((attribute, key) => {
                if (!this.state.modelType.bluePrint[attribute].hidden) {
                  if ((this.state.modelType.bluePrint[attribute].dataType === "String" ||
                    this.state.modelType.bluePrint[attribute].dataType === "EnumDropDown") &&
                    item[attribute] && item[attribute].toLowerCase().includes(searchText)) {
                    // console.log(item[attribute].toLowerCase());
                    // newTempList.push(item);
                    throw Object.assign(new Error({ status: false }));
                  } else if (this.state.modelType.bluePrint[attribute].dataType === "Number" &&
                    item[attribute] && item[attribute].toString().toLowerCase().includes(searchText)) {
                    throw Object.assign(new Error({ status: false }));
                  }
                }
              })
            } catch (error) {
              newTempList.push(item);
            }


          })

        }
        this.setState({ listFiltered: newTempList, searching: false, searched: true })
      }
    }
  }
  // handleSelectedActivePage = selectedPage => {
  //   let activePagination = this.state.activePagination;
  //   activePagination.selectedPage = selectedPage;
  //   this.setState({ activePagination });
  // };

  generateNavigateParams = (params, obj) => {
    let pramsObj = {}
    params.forEach(p => {
      pramsObj[p.name] = p.field ? obj[p.field] : p.value
    })
    return JSON.stringify(pramsObj);
  }


  restrictNavigationParams = (restrict, obj) => {
    let allow = restrict ? false : true;
    restrict && restrict.forEach((r, i) => {
      if (r.value === obj[r.field]) {
        allow = true;
      }
    })
    return allow;
  }
  sortByColumn = (col) => {
    const { listFiltered, modelType } = this.state;
    const { bluePrint } = modelType
    function compare(a, b) {
      if (bluePrint[col].dataType === "ParentDropDown") {
        let colObjName = col + "Obj";
        if (a[colObjName][bluePrint[col].displayfield] < b[colObjName][bluePrint[col].displayfield]) {
          return -1;
        }
        if (a[colObjName][bluePrint[col].displayfield] > b[colObjName][bluePrint[col].displayfield]) {
          return 1;
        }
      } else {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
      }
      return 0;

    }
    this.setState({ listFiltered: listFiltered.sort(compare) })
  }

  getListIndex = (id) => {
    return this.state.list.findIndex(g => g._id == id);
  }
  changeLevel = (id, withId, up) => {
    const { list, route } = this.state;
    let obj = list.find(m => m._id == id);
    let objbjRowLevel = obj.rowLevel;

    let withObj = list.find(m => m._id == withId);
    let withObjRowLevel = withObj.rowLevel;
    if (objbjRowLevel === withObjRowLevel) {
      if (up) {
        objbjRowLevel = -1;
      } else {
        objbjRowLevel = +1;
      }
    }

    obj.rowLevel = withObjRowLevel;
    apiService.update(route, obj, obj._id);

    withObj.rowLevel = objbjRowLevel;
    apiService.update(route, withObj, withObj._id)
    // .then(g=>{
    //   this.fetchData(route);
    // });
  }
  handleExport = () => {

    const query = new URLSearchParams(this.props.location.search);
    let filterQuery = null;
    if (query.get("filter")) {
      filterQuery = JSON.parse(JSON.stringify(query.get("filter")));
    }
    apiService.add(`${this.state.route}/export/excel`, { query: JSON.parse(filterQuery), sortQuery: { dateTime: - 1 } })
      .then(({ path }) => {
        var win = window.open(config.baseUrl + "/" + path, '_blank');
        win.focus();
      })
      .catch(e => {
        toast.error("An error occured while exporting!")
      })
  }
  fillURLAddressWithPage = (number) => {
    let search = this.props.location.search;
    let prefix = '?';
    const query = new URLSearchParams(this.props.location.search);
    if (query.get("filter")) {
      prefix = '&'
    }
    if (search.includes("page=")) {
      let searchArr = search.split("page=");
      searchArr[1] = "page=" + number;
      search = searchArr.join("");
    } else {
      search += `${prefix}page=${number}`;
    }
    return search;
  }


  clearSearch = () => {
    this.setState({ searchText: "" }, () => {
      this.fetchData(this.state.route, 1);
    })
  }

  handleClickAllCheckBox = () => {
    const { selectedRows, per_page, listFiltered } = this.state;

    if (selectedRows.length === per_page) {
      this.setState({ selectedRows: [] });
    } else {
      let _list = [];
      listFiltered.forEach(obj => {
        _list.push(obj._id);
      });
      this.setState({ selectedRows: _list });
    }
  }

  isRowSelected = (_id) => {
    return this.state.selectedRows.findIndex(s => s === _id)
  }
  handleClickCheckBox = (_id) => {
    let index = this.isRowSelected(_id);
    let list = JSON.parse(JSON.stringify(this.state.selectedRows));
    if (index < 0) {
      list.push(_id);
    } else {
      list.splice(index, 1);
    }
    this.setState({ selectedRows: list });
  }

  handleActionClick = (code) => {
    const { selectedRows, route, current_page } = this.state;
    if (window.confirm(`Are you really want to ${code} selected ${route}?`)) {
      toast.info('Please Wait...');
      apiService.add(`${route}/action/${code}`, selectedRows)
        .then(res => {
          toast.success(`Success!`);
          this.fetchData(route, current_page);
          this.setState({ selectedRows: [] })
        }).catch(({ response }) => {
          toast.error((response?.data?.message) ? response.data.message : `An error occured while performing ${code} action`);
        })
    }
  }
  render() {
    // const list = categoriesData
    let renderPageNumbers;
    const {
      list,
      listFiltered, modelType, route, loaded, total, current_page, per_page, list_all, searched, searchText, filtered, selectedRows } = this.state;


    let totalPages = Math.ceil(total / per_page);

    const pageNumbers = [];
    if (total !== null) {
      let target = Math.ceil(current_page + per_page);
      target = target > totalPages ? totalPages : target;
      for (let i = current_page; i <= target; i++) {
        pageNumbers.push(i);
      }


      renderPageNumbers = pageNumbers.map(number => {
        let classes = current_page === number ? 'pagination-link active' : 'pagination-link';

        return (
          <Link key={number} className={classes} to={`/${pluralize.plural(route)}/${this.fillURLAddressWithPage(number)}`}>{number}</Link>
        );
      });
    }
    return (
      <div className="animated fadeIn">
        <ToastContainer />
        <Row>
          <Col xl={12}>
            {modelType &&
              <Card>
                <CardHeader>
                  <i className={modelType.icon}></i> {pluralize.plural(modelType.modelDisplayName)} {total ? `(${total})` : null}
                  {modelType.permission && modelType.permission.create &&
                    <Link
                      to={`/${pluralize.singular(route)}/add${this.props.location.search}`}
                      className="btn btn-primary btn-sm"
                      style={{ float: "right" }}
                    >
                      Add New
                    </Link>
                  }
                  {modelType.permission && modelType.permission.export &&
                    <button
                      onClick={this.handleExport}
                      className="btn btn-primary btn-sm mr-2"
                      style={{ float: "right" }}
                    >
                      Export
                    </button>
                  }
                </CardHeader>
                <CardBody>
                  {!filtered && modelType.permission && modelType.permission.search && <form class="input-group" onSubmit={this.handleSearch}>
                    <input type="text" class="form-control" placeholder="Search" value={searchText} onChange={({ target }) => this.setState({ searchText: target.value })} />
                    <div class="input-group-append">
                      <button className="btn btn-primary btn-sm" type="submit">
                        Search
                      </button>
                      <button className="btn btn-warning btn-sm" type="button" onClick={this.clearSearch}>
                        Clear
                      </button>
                    </div>
                  </form>}


                  <Table responsive hover>
                    <thead>
                      <tr>
                        {modelType.bulkActions && <th onClick={this.handleClickAllCheckBox}>
                          <input type="checkbox" checked={per_page === selectedRows.length} />
                        </th>}
                        {Object.keys(modelType.bluePrint).map((th, key) => {
                          if (modelType.bluePrint[th].displayOnHeader && !modelType.bluePrint[th].hidden)
                            return <th scope="col" key={key} onClick={() => this.sortByColumn(th)}>{modelType.bluePrint[th].label}</th>
                          else
                            return null;

                        })}
                        <th key={100} style={{ width: 100 }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>

                      {loaded && listFiltered.length == 0 && <tr > <td colspan="12" className="text-center">No {pluralize.plural(modelType.modelDisplayName)} found!</td> </tr>}
                      {listFiltered
                        // .slice(
                        //   searched ? (this.state.activePagination.selectedPage - 1) * this.state.activePagination.pageSize : total,
                        //   searched ? this.state.activePagination.selectedPage * this.state.activePagination.pageSize : total
                        // )
                        .map((formObj, index) => (
                          <tr key={formObj._id.toString()}>
                            {
                              modelType.bulkActions && <td onClick={() => this.handleClickCheckBox(formObj._id)}>
                                <input type="checkbox" checked={this.isRowSelected(formObj._id) !== -1} />
                              </td>
                            }
                            {
                              Object.keys(modelType.bluePrint).map((td, key) => {
                                if (modelType.bluePrint[td].displayOnHeader && !modelType.bluePrint[td].hidden)
                                  if (modelType.bluePrint[td].dataType === "String" ||
                                    modelType.bluePrint[td].dataType === "EnumDropDown" ||
                                    modelType.bluePrint[td].dataType === "Number" ||
                                    modelType.bluePrint[td].dataType === "AddressBar")
                                    return <td key={key}>{formObj[td]}</td>

                                  else if (modelType.bluePrint[td].dataType === "RowLevel")
                                    return <td key={key} className="rl-container">
                                      {this.getListIndex(formObj._id) !== 0 && <i class="fa fa-arrow-up"
                                        aria-hidden="true"
                                        onClick={() => this.changeLevel(formObj._id, list[this.getListIndex(formObj._id) - 1]._id, true)}
                                      ></i>}

                                      {/* <span className="rl-value">{formObj[td]}</span> */}
                                      {list.length - 1 !== this.getListIndex(formObj._id) && <i class="fa fa-arrow-down"
                                        onClick={() => this.changeLevel(formObj._id, list[this.getListIndex(formObj._id) + 1]._id, false)}
                                        aria-hidden="true"></i>}
                                    </td>
                                  else if (modelType.bluePrint[td].dataType === "Image")
                                    return <td key={key}>
                                      <img src={getImageURL(formObj[td])} alt={td} style={{ height: 70 }} />
                                    </td>

                                  else if (modelType.bluePrint[td].dataType === "Boolean")
                                    return <td key={key}>{formObj[td] ? "Yes" : "No"}</td>

                                  else if (modelType.bluePrint[td].dataType === "ParentDropDown")
                                    return <td key={key}>{formObj[td + "Obj"][modelType.bluePrint[td].displayfield]}</td>

                                  else if (modelType.bluePrint[td].dataType === "Date" || modelType.bluePrint[td].dataType === "DateTime" || modelType.bluePrint[td].dataType === "DefaultDate")
                                    return <td key={key}>{new Date(formObj[td]).toLocaleDateString()} {new Date(formObj[td]).toLocaleTimeString()}</td>
                                  else if (modelType.bluePrint[td].dataType === "RatingSelector")
                                    return <td key={key}><RatingSelector value={formObj[td]} /></td>

                                  else
                                    return null;
                                else
                                  return null;
                              })

                            }


                            <td>
                              <Link to={`/${route}/detail/${formObj._id}${this.props.location.search}`} className="mr-2"> <i className="fa fa-info"></i></Link>
                              {modelType.permission && modelType.permission.edit &&
                                <Link to={`/${pluralize.singular(route)}/edit/${formObj._id}${this.props.location.search}`} className="text-warning">
                                  <i className="fa fa-edit"></i>
                                </Link>
                              }
                              {modelType.permission && modelType.permission.delete &&
                                <Link to={`/${route}/delete/${formObj._id}${this.props.location.search}`} className="ml-2 text-danger">
                                  <i className="icon-trash"></i>
                                </Link>
                              }

                              {modelType.actions && modelType.actions.map(action => this.restrictNavigationParams(action.restrict, formObj) && <div className="row">
                                <Link to={`/${action.route}?filter=${this.generateNavigateParams(action.params, formObj)}`} className="ml-2 text-primary">
                                  {action.value}
                                </Link>
                              </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {/* <PaginationComponent
                    totalItems={listFiltered.length}
                    pageSize={this.state.activePagination.pageSize}
                    onSelect={this.handleSelectedActivePage}
                    maxPaginationNumbers={
                      this.state.activePagination.maxPaginationNumbers
                    }
                    activePage={this.state.activePagination.selectedPage}
                  /> */}

                  {current_page && !searched && <div
                    className="pagination"
                  >
                    <Link className="pagination-link" to={`/${pluralize.plural(route)}/${this.fillURLAddressWithPage(1)}`}>&laquo;</Link>
                    <Link className="pagination-link" to={`/${pluralize.plural(route)}/${this.fillURLAddressWithPage((current_page - per_page) < 1 ? 1 : current_page - per_page)}`}>&lt;</Link>
                    {renderPageNumbers}
                    <Link className="pagination-link" to={`/${pluralize.plural(route)}/${this.fillURLAddressWithPage((current_page + per_page) > totalPages ? totalPages : current_page + per_page)}`}>&gt;</Link>

                    <Link className="pagination-link" to={`/${pluralize.plural(route)}/${this.fillURLAddressWithPage(totalPages)}`}>&raquo;</Link>
                    {/* <span onClick={() => this.makeHttpRequestWithPage(1)}>&raquo;</span> */}
                  </div>}

                  {modelType.bulkActions && <div className="row p-3">
                    <div className="col-sm-2">
                      <button className={`btn btn-primary btn-block`} onClick={this.handleClickAllCheckBox}>
                        {selectedRows.length === per_page ? 'Unselect All' : `Select All`}
                      </button>
                    </div>
                    {modelType.bulkActions.map(bulkAction => <div className="col-sm-2">
                      <button disabled={selectedRows.length === 0} className={`btn btn-${bulkAction.buttonClass} btn-block`} onClick={() => this.handleActionClick(bulkAction.code)}>
                        {bulkAction.name}
                      </button>
                    </div>)}
                  </div>}

                </CardBody>
              </Card>
            }
          </Col>
        </Row>
      </div>
    );
  }
}

class Detail extends Component {
  state = {
    formObj: null,
    modelType: null,
    loading: true,
    status: false,
    delete: false
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    let { location } = this.props;

    let route = pluralize.plural(location.pathname.split("/")[1]);
    this.setState({ route });

    apiService.type(route).then(m => {
      this.setState({ modelType: m });
    })
    apiService.getSingle(route, this.props.match.params.id).then(formObj => {
      this.setState({ formObj, status: formObj.status, delete: pathname.includes("delete"), loading: false })
    }).catch(e => {
      console.log(e.response)
    })
  }


  render() {
    const { formObj, loading, modelType, route } = this.state;
    return (
      <div>
        {
          loading ? <span><i className="text-muted icon-wait"></i> Loading...</span> :
            formObj && modelType ? <div className="animated fadeIn" >
              <Row>
                <Col lg={8}>
                  <Card>
                    <CardHeader>
                      <strong><i className={`${modelType.icon} pr-1`}></i>{modelType.modelDisplayName}</strong>
                      <br />
                      <Link to={`/${route}${this.props.location.search}`}>Back to List</Link>
                    </CardHeader>
                    <CardBody>
                      <Table striped hover>
                        <tbody>
                          <FieldViewer
                            modelType={modelType}
                            formObj={formObj}
                          />

                          {this.state.delete &&
                            <tr>
                              <td>
                                Comfirmation:
                              </td>
                              <td>
                                <button className="btn btn-danger btn-sm" type="button" onClick={() => { apiService.delete(route, formObj._id).then(d => { this.props.history.push(`/${route}`) }) }}>
                                  <i className="icon-trash"></i> Delete
                                </button>
                                <button className="btn btn-info btn-sm text-white ml-1" type="button" onClick={() => { this.props.history.push(`/${route}`) }}>Cancel</button>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>


              </Row>
            </div>
              : <span><i className="text-muted icon-ban"></i> Not found</span>
        }
      </div>
    )

  }
}

class Manage extends Component {
  state = {
    formObj: null,
    validationRule: null,
    errors: {},
    formError: null,
    loaded: 0,
    pageLoading: true,
    isLoading: true,
    modelType: null,
    single: false

  }


  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let { location } = this.props;
    let route = pluralize.plural(location.pathname.split("/")[1]);
    this.setState({ route });

    const query = new URLSearchParams(this.props.location.search);
    if (query.get("single")) {
      this.setState({ single: true })
    }


    apiService.type(route).then(modelType => {
      //Define Validation Rule
      let validationRule = {};
      Object.keys(modelType.bluePrint).forEach((attribute, key) => {
        if (!modelType.bluePrint[attribute].hidden && !modelType.bluePrint[attribute].hide_form && !modelType.bluePrint[attribute].showInTable && (modelType.bluePrint[attribute].dataType !== "Boolean" || modelType.bluePrint[attribute].dataType !== "DefaultDate")) {

          if (modelType.bluePrint[attribute].required) {
            validationRule[attribute] = { presence: { allowEmpty: false } }
          }
          if (modelType.bluePrint[attribute].dataType === "Number") {
            validationRule[attribute] = { presence: { allowEmpty: false }, format: "\\d*\\.?\\d+" }
          }
        }
      });
      console.log({ validationRule });
      this.setState({ modelType, validationRule });
      if (this.props.match.params.id) {
        apiService.getSingle(route, this.props.match.params.id).then(formObj => {
          Object.keys(modelType.bluePrint).forEach((attribute, key) => {
            if (!modelType.bluePrint[attribute].hidden && !modelType.bluePrint[attribute].showInTable)
              if (modelType.bluePrint[attribute].dataType === "DateTime" || modelType.bluePrint[attribute].dataType === "Date") {
                formObj[attribute] = new Date(formObj[attribute]);
              }
              else if (modelType.bluePrint[attribute].dataType === "RichText")
                formObj[attribute] = (!formObj[attribute]) ? "" : formObj[attribute];
              else if (modelType.bluePrint[attribute].dataType === "Number")
                formObj[attribute] += "";

            Object.keys(modelType.bluePrint).forEach((at, indx) => {
              if (modelType.bluePrint[at].dataType && modelType.bluePrint[at].loadVia === attribute) {
                modelType.bluePrint[at]["loadViaId"] = formObj[attribute];
              }
            });

          });

          this.setState({ formObj, isLoading: false })
        }).catch(m => {
          this.props.history.push(`/${route}`)
        })
      } else {

        let formObj = {
          _id: ""
        }
        Object.keys(modelType.bluePrint).forEach((attribute, key) => {
          if (!modelType.bluePrint[attribute].hidden && !modelType.bluePrint[attribute].showInTable && (modelType.bluePrint[attribute].dataType === "Date" || modelType.bluePrint[attribute].dataType === "DefaultDate")) {
            formObj[attribute] = new Date();
          } else {
            let defaultVal = modelType.bluePrint[attribute].default;
            let dataType = modelType.bluePrint[attribute].dataType;
            let nValue = defaultVal;
            if (dataType === "String" || dataType == "Number") {
              if (defaultVal != undefined && defaultVal != "undefined") {
                nValue = defaultVal + "";
              } else {
                nValue = ""
              }
            }
            formObj[attribute] = nValue;
          }
        });

        if (query.get("filter")) {
          let filterQuery = JSON.parse(query.get("filter"));
          Object.keys(filterQuery).forEach(fq => {
            formObj[fq] = filterQuery[fq];
          })
        }
        this.setState({ formObj, isLoading: false });
      }
    })


  }

  convertAllParametersToString(ad) {
    for (var prop in ad) {
      ad[prop] += "";
    }
    return ad;
  }
  static get RULES() {
    return {
      title: {
        presence: {
          allowEmpty: false
        }
      }
    };
  }
  handleChange({ target }) {
    const { name, value } = target;
    const errors = validate({ [name]: value }, { [name]: this.state.validationRule[name] });
    let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
    this.setState({
      formObj: obj,
      errors: Object.assign(
        {},
        this.state.errors,
        errors ? errors : { [name]: undefined }
      )
    });
  }
  handleChageCustom = (name, value) => {
    const errors = validate({ [name]: value }, { [name]: this.state.validationRule[name] });
    let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
    this.setState({
      formObj: obj,
      errors: Object.assign(
        {},
        this.state.errors,
        errors ? errors : { [name]: undefined }
      )
    });
  }
  handleChangeBoolean = ({ target }) => {
    const { name } = target;
    let value = this.state.formObj[name] ? !this.state.formObj[name] : true;

    let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
    this.setState({
      formObj: obj
    });
  }

  handleChangeLocation = (res) => {
    if (this.state.formObj) {
      let tFromObj = JSON.parse(JSON.stringify(this.state.formObj));
      Object.keys(res).forEach(rK => {
        tFromObj[rK] = res[rK];
      });
      this.setState({ formObj: tFromObj });
    }

  }

  fillInObject(obj, name, value) {
    // console.log(value);
    obj[name] = value;
    return obj;
  }

  isContainProps(ad) {
    let rt = false;
    for (var prop in ad) {
      if (ad[prop] && ad.hasOwnProperty(prop)) {
        rt = true;
        // handle prop as required
      }
    }
    return rt;
  }

  handleSubmit(e) {
    e.preventDefault();
    // if (this.state.formObj.image) {
    const { formObj, validationRule, modelType, route, single } = this.state;
    let formObjClone = JSON.parse(JSON.stringify(formObj))
    const errors = validate(formObj, validationRule);
    if (errors) {
      toast.error("Form data is Invalid")
      return this.setState({ formError: null, errors });
    }

    let bluePrintKeys = Object.keys(modelType.bluePrint);
    bluePrintKeys.filter(g => g.includes("GeoFence")).forEach((attr, atrKey) => {
      let element = formObjClone[attr];
      if (element && element.coordinates && element.coordinates[0].length > 2) {
        let lastIndex = element.coordinates[0].length - 1;
        if (element.coordinates[0][0][0] != element.coordinates[0][lastIndex][0] && element.coordinates[0][0][1] != element.coordinates[0][lastIndex][1]) {
          formObjClone[attr].coordinates[0].push(element.coordinates[0][0]);
        }
        console.log(JSON.stringify(formObjClone[attr].coordinates));
      } else {
        let geoFenceError = `Please Select Geo Fence Area`;
        toast.error(geoFenceError)
        return this.setState({ formError: geoFenceError })
      }
    });

    try {
      bluePrintKeys.forEach(attr => {
        if (modelType.bluePrint[attr].dataType === "ChildGrid") {
          const attribute = modelType.bluePrint[attr];
          let nestedTypeKeys = Object.keys(attribute.bluePrint);
          nestedTypeKeys.every((nestAttr, nestKey) => {
            if (attribute.bluePrint[nestAttr].dataType == "String" && attribute.bluePrint[nestAttr].required) {
              if (formObjClone[attr][0][nestAttr] === "") {
                delete formObjClone[attr];
                throw null;
              }
            }
          });
        }
      })
    } catch (error) { }

    this.setState({ isLoading: true }, () => {
      if (!formObjClone._id) {
        apiService.add(route, formObjClone).then(res => {

          if (!single) {
            if (res._message) {
              toast.info(res._message);
              setTimeout(() => {
                this.props.history.push(`/${route}${this.props.location.search}`);
              }, 3000)
            } else {
              this.props.history.push(`/${route}${this.props.location.search}`);
            }

          }

        }).catch(err => {
          toast.error(err.response.data.message);
          this.setState({ isLoading: false })
        })
      } else {
        apiService.update(route, formObjClone, formObjClone._id).then(res => {
          if (!single) {
            this.props.history.push(`/${route}${this.props.location.search}`)
          } else {
            toast.success("Successfully Updated!");
            this.setState({ isLoading: false })
          }

        }).catch(err => {
          toast.error(err.response.data.message);
          this.setState({ isLoading: false })
        })
      }
    })



    // } else {
    //   toast.error("Please Upload Packege Image")
    // }
  }
  render() {
    const { formObj, isLoading, errors, modelType, route, single } = this.state;

    return (
      <div>
        {
          isLoading ? <span><i className="text-muted icon-wait"></i> Loading...</span> :
            formObj ? <div className="animated fadeIn" >
              <Row>
                <Col lg={8}>
                  <form onSubmit={e => this.handleSubmit(e)} >
                    <ToastContainer></ToastContainer>
                    {this.state.formError ? (
                      <Notification type="danger" onCloseBtnClick={e => this.setState({ formError: null })}>
                        {this.state.formError}
                      </Notification>
                    ) : null}
                    <Card>
                      <CardHeader>
                        <div className="form-topbar">
                          <div className={"form-topbar-title{"}>
                            <strong><i className={`${modelType.icon} pr-1`}></i>{modelType.modelDisplayName} Manage</strong>
                          </div>
                          <div className={"form-topbar-options"}>
                            {
                              !single && <Link to={`/${route}${this.props.location.search}`} className="btn btn-danger btn-sm mr-1" style={{ width: 60 }} disabled={isLoading}>Cancel</Link>
                            }

                            <button className={`btn btn-success btn-sm ${single ? "btn-block" : ""}`} style={{ width: single ? null : 60 }} disabled={isLoading}>Save</button>

                          </div>
                        </div>

                      </CardHeader>
                      <CardBody>
                        <FieldsGenerator
                          modelType={modelType}
                          errors={errors}
                          formObj={formObj}
                          handleChange={(e) => this.handleChange(e)}
                          handleChageCustom={this.handleChageCustom}
                          handleChangeBoolean={this.handleChangeBoolean}
                          handleChangeLocation={this.handleChangeLocation}
                          isLoading={isLoading}
                          onchangeModelType={(modelType) => this.setState({ modelType })}
                        />
                      </CardBody>
                    </Card>
                  </form>
                </Col>


              </Row>
            </div>
              : <span><i className="text-muted icon-ban"></i> Not found</span>
        }
      </div>
    )

  }
}

function mapStateToProps(state) {
  const { categories } = state;
  // const {token, admin} = authentication;
  return {
    categories
  };
}

const connectedDataTable = connect(mapStateToProps)(withRouter(DataTable));
const connectedDetail = connect(mapStateToProps)(withRouter(Detail));
const connectedManage = connect(mapStateToProps)(withRouter(Manage));
export { connectedDataTable as DataTable, connectedDetail as Detail, connectedManage as Manage };